import React, {FunctionComponent} from 'react';

import {Translate} from '../../components/translations';
import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';

const GDPR: FunctionComponent = () => (
  <div className="content-wrapper">
    <PageHero title="HEXOMETER_GDPR_TITLE" />
    <Layout>
      <Section>
        <div className="col-lg-12">
          <Translate name="HEXOMETER_GDPR_CONTENT" />
        </div>
      </Section>
    </Layout>
  </div>
);

export default GDPR;
